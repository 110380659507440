import Dexie, { Table } from 'dexie'
import { constants } from '../config'
import { formatISO } from 'date-fns'

// Increment this on db structure changes !!
// Need a data migration, see: https://dexie.org/docs/Tutorial/Design#database-versioning
const DB_DESIGN_VERSION = 43

export type MenuOptions = 'start' | 'user' | 'walks' | 'register' | 'help'
export type TableName = 'users' | 'walks' | 'plbs' | 'messages'
export type PushingTable = TableName | ''
export type SyncStatus =
  | ''
  | 'syncing'
  | 'needed'
  | 'appStart'
  | 'done'
  | 'error'
  | 'bigError'
export type UserStatus = '' | 'active' | 'deleted'
export type WalkStatus =
  | 'draft'
  | 'open'
  | 'started'
  | 'ended'
  | 'cancelled'
  | 'deleted'
export type WalkCategory = '' | 'multiday' | 'campWalk' | 'social' | 'other' // Default is '' = day walk
export type PlbStatus = 'draft' | 'inUse' | 'deleted'
export type MessageStatus = 'draft' | 'sent' | 'deleted'
export type MessageToTypes = '' | 'all' | 'walk' | 'user'
export type StandardMessages = 'walkEnded' | 'walkCancelled'
export type SyncLock = 'sync-lock'
export type IsClosed = 'is-closed'
export type FilesMeta = 'files-meta'
export type FilesContents = 'files-contents'

export const walkStatusses: WalkStatus[] = [
  'draft',
  'open',
  'started',
  'ended',
  'cancelled',
  'deleted',
]

export type SyncActions = '' | 'pull-only'
export type SyncOptions = {
  action: SyncActions
  sanityCheck?: {
    registerFor: string
  }
}
export interface AppStatus {
  appName: string
  navTo: MenuOptions
  userId: string
  clubId: string
  headerMessage: string
  haveInternet: boolean
  isDarkTheme: boolean
  isClosed: boolean
  parameters: string
  messagesSeenTo: string
  zoomDefault: number
  sync: {
    status: SyncStatus
    pushingTable: PushingTable
    message: string
    btnText: string
    lastSyncAt: string
    usersRemoteId: string
    walksRemoteId: string
    plbsRemoteId: string
    messagesRemoteId: string
    usersLocalUpdates: boolean
    walksLocalUpdates: boolean
    plbsLocalUpdates: boolean
    messagesLocalUpdates: boolean
    options: SyncOptions
  }
}

export interface User {
  userId: string
  createdAt: string
  updatedAt: string
  firstName: string
  fullName: string
  lastName: string
  phone: string
  emergencyPhone: string
  emergencyName: string
  emergencyPhone1: string
  emergencyName1: string
  contactBy: 'club' | 'services'
  address: string
  isSuperAdmin: boolean
  isVisitor: boolean
  isPrivateDetails: boolean
  isBlocked: boolean
  isAdmin: boolean
  receiveUpdates: boolean
  medicalForm: boolean
  medicalFormLocation: string
  password: string
  holdsPlbId: string
  status: UserStatus
}

export type TableBackup = {
  timestamp: string
  signature: string
  items: Walk[] | User[] | Plb[] | Message[]
}

export enum WalkerChangeType {
  NoChange = 0,
  Added = 1,
  Removed = -1,
}

export type WalkerChanges = {
  [walkerId: string]: WalkerChangeType
}

export interface Walk {
  walkId: string
  createdAt: string
  updatedAt: string
  leaderId: string | string[] // Not plural to avoid a data migration
  status: WalkStatus
  category: WalkCategory
  title: string
  date: string
  dateTo: string
  meetPoint: string
  meetTime: string
  startPoint: string
  startTime: string
  endTime: string
  startGps: string
  endGps: string
  isFull: boolean
  capacity: string
  startedAt: string
  endedAt: string
  notes: string
  walkers: string[]
  walkerChangesByLeader: WalkerChanges
  fileIds: string[]
  belongsToId: string // The parent camp of a camp walk
}

export interface Plb {
  plbId: string
  createdAt: string
  updatedAt: string
  serialNumber: string
  label: string
  contactUserIds: string[]
  heldByUserId: string
  notes: string
  status: PlbStatus
}

export interface Message {
  messageId: string
  createdAt: string
  updatedAt: string
  sentAt: string
  status: MessageStatus
  fromUserId: string
  to: MessageToTypes
  toId: string
  message: string
}

interface LogItemDex {
  id?: number
  repeat: number
  time: string
  data: any
}

export type FileMeta = {
  walkId: string
  walkTitle: string
  fileId: string
  fileNameType: string
  mimeType: string
  uploadedAt: string
}

export class TripsheetsDexie extends Dexie {
  appStatus!: Table<AppStatus>
  users!: Table<User>
  walks!: Table<Walk>
  plbs!: Table<Plb>
  messages!: Table<Message>
  logItems!: Table<LogItemDex>

  constructor() {
    try {
      super('TripsheetsDb')

      // Create table indexes
      this.version(DB_DESIGN_VERSION).stores({
        appStatus: 'appName',
        users: 'userId',
        walks: 'walkId',
        plbs: 'plbId',
        messages: 'messageId',
        logItems: '++id',
      })

      // Initial definition of the tables
      this.on('populate', function (transaction) {
        // @ts-ignore - appStatus is not known on transaction
        transaction.appStatus.add({
          appName: 'Tripsheets',
          navTo: 'start',
          clubId: '',
          headerMessage: '',
          parameters: '',
          haveInternet: false,
          isClosed: false,
          zoomDefault: 0,
          sync: {
            status: '',
            message: '',
            lastSyncAt: '',
            usersRemoteId: '',
            walksRemoteId: '',
            options: { action: '' },
          },
        })
      })

      // Reset some statusses when db is ready (ie the app opens)
      this.on('ready', async (db) => {
        // First find my club. It must match the environment
        let clubId = ''
        // @ts-ignore - appStatus unknown on Dexie
        const currentAppStatus = await db.appStatus.get('Tripsheets')
        if (currentAppStatus) {
          clubId = !currentAppStatus.clubId
            ? constants.ENVIRONMENT
            : currentAppStatus.clubId === constants.ENVIRONMENT
            ? constants.ENVIRONMENT
            : ''
        }

        const statusOnStart = {
          clubId,
          isClosed: false,
          messagesSeenTo:
            currentAppStatus?.messagesSeenTo ||
            formatISO(new Date(Date.now() - 24 * 60 * 60 * 1000)), // By default, show msgs from the last 24hrs
          sync: {
            ...(currentAppStatus?.sync || {}),
            status: 'appStart',
            message: '',
            parameters: '',
            options: { action: '' },
          },
        }

        // @ts-ignore - appStatus unknown on Dexie
        db.appStatus.update('Tripsheets', statusOnStart)
      })
    } catch (error: any) {
      console.error('Error during Dexie start', error)
      throw new Error(error)
    }
  }
}

export const db = new TripsheetsDexie()
