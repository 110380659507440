import React, { Fragment } from 'react'
import { Box, Button, HStack, IconButton } from '@chakra-ui/react'
import { LuArrowBigRight, LuFootprints, LuLightbulbOff } from 'react-icons/lu'
import {
  ArrowRightIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
} from '@chakra-ui/icons'
import { Walk, WalkStatus } from '../store/db'
import { ButtonConfirm } from '../common/UtilComponents'

import './walks.css'
import { palette } from '../config'
import { formatWalkTitle } from '../common/utils'

type Props = {
  editWalkId: string
  draftWalk?: Partial<Walk>
  doEditWalk: (status?: WalkStatus) => Promise<void>
}

export const StatusButtons = (props: Props) => {
  // Note: leaving Start and End statusses to the walk component
  let newStatusses: (WalkStatus | 'nochange')[] = []
  switch (props?.draftWalk?.status) {
    case undefined:
      newStatusses = ['draft', 'open', 'deleted']
      break
    case 'draft':
      newStatusses = ['open', 'deleted', 'nochange']
      break
    case 'open':
      newStatusses = ['draft', 'cancelled', 'nochange']
      break
    case 'started':
      newStatusses = ['draft', 'cancelled', 'nochange']
      break
    case 'ended':
      newStatusses = ['draft', 'nochange']
      break
    case 'cancelled':
      newStatusses = ['draft', 'deleted', 'nochange']
      break
  }

  return (
    <>
      <HStack mt={4} spacing={4} justify={'end'} w={'100%'}>
        {newStatusses.map((status) => {
          const isCurrent = props?.draftWalk?.status === status
          switch (status) {
            case 'draft':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={'so only visible for you'}
                    btnText={'Draft'}
                    okAction={() => props.doEditWalk('draft')}
                    isDisabled={!props.draftWalk?.title}
                    icon={<CheckIcon />}
                  />
                </Box>
              )
            case 'open':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={'for registrations'}
                    btnText={'Open'}
                    okAction={() => props.doEditWalk('open')}
                    isDisabled={!props.draftWalk?.title}
                    icon={<LuFootprints />}
                  />
                </Box>
              )
            case 'ended':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={`walk ${
                      formatWalkTitle(props.draftWalk?.title, true) || ''
                    }`}
                    btnText={'End'}
                    okAction={() => props.doEditWalk('ended')}
                    isDisabled={!props.draftWalk?.title}
                    icon={<LuFootprints />}
                  />
                </Box>
              )
            case 'deleted':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={`walk ${
                      formatWalkTitle(props.draftWalk?.title, true) || ''
                    }`}
                    btnText={'Delete'}
                    okAction={() => props.doEditWalk('deleted')}
                    icon={<DeleteIcon />}
                  />
                </Box>
              )
            case 'cancelled':
              return (
                <Box
                  key={`btn-${status}`}
                  className={isCurrent ? 'redBorder' : ''}
                >
                  <ButtonConfirm
                    text={`walk ${
                      formatWalkTitle(props.draftWalk?.title, true) || ''
                    }`}
                    btnText={'Cancel walk'}
                    okAction={() => props.doEditWalk('cancelled')}
                    icon={<LuLightbulbOff />}
                  />
                </Box>
              )
            case 'nochange':
              return (
                <Fragment key={`btn-${status}`}>
                  <IconButton
                    icon={<CheckIcon />}
                    size={'sm'}
                    colorScheme={palette.action}
                    onClick={() => props.doEditWalk()}
                    aria-label={'Save changes'}
                  />
                  {/*<ButtonConfirm*/}
                  {/*  text={`Walk stays ${props.draftWalk?.status || ''}`}*/}
                  {/*  btnText={''}*/}
                  {/*  color={palette.action}*/}
                  {/*  okAction={() => props.doEditWalk()}*/}
                  {/*  icon={<CheckIcon />}*/}
                  {/*/>*/}
                </Fragment>
              )
          }
        })}
      </HStack>
    </>
  )
}
