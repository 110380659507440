import React, { useEffect, useState } from 'react'

type Props = {
  source: string
}

// nextttt
//    swipes here: left = close, right = next
//    close main menu
//    carousel pics?

export const Help = (props: Props) => {
  return <>help for {props.source}!</>
}
