import {
  AppStatus,
  db,
  Message,
  Plb,
  PushingTable,
  TableName,
  User,
  Walk,
} from '../store/db'
import { deleteS3File, getS3FileContents, getS3Filenames } from './s3Functions'
import { log } from './utils'
import {
  ERROR,
  ERROR_GETTING_CONTENT,
  MESSAGE_SEPARATOR,
  updateSingleSyncProperty,
  WARNING,
} from './syncWithRemote'
import { isEqual } from 'lodash'
import { replaceWalksTable } from '../Walks/walks.model'
import { replaceUsersTable } from '../User/user.model'
import { replacePlbsTable } from '../Plbs/plbs.model'
import { replaceMessagesTable } from '../Messages/messages.model'

type ChangesStatus = {
  localChanges: boolean
  remoteChanges: boolean
  error: boolean
}

export const findChangesStatus = async (
  table: TableName,
  appStatus?: AppStatus
): Promise<ChangesStatus> => {
  if (!appStatus) {
    await updateSingleSyncProperty([
      { key: 'btnText', value: 'Retry' },
      { key: 'status', value: 'error' },
      {
        key: 'message',
        value: `Oops - Please CLICK AGAIN to retry`,
      },
    ])
    return {
      localChanges: false,
      remoteChanges: false,
      error: true,
    }
  }

  // Verify that previous pushToRemote actually finished
  const pushingTable: PushingTable = appStatus.sync.pushingTable
  if (pushingTable) {
    log(`WPUSHBROK ${pushingTable.slice(0, 1)}`, true, {
      userId: appStatus.userId,
    })

    const msg = [WARNING]
    msg.push(
      `It looks like the previous sync for the ${pushingTable} was broken off`
    )
    msg.push(`Please always wait for the sync tick before closing the app`)
    await updateSingleSyncProperty([
      { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
      { key: 'status', value: 'bigError' },
      { key: 'pushingTable', value: '' },
    ])
  }

  // Verify that the local and remote sides look equalish
  const verifyError = await verifyLocalRemote(table, appStatus)
  if (verifyError) {
    await updateSingleSyncProperty([
      { key: 'status', value: 'bigError' },
      { key: 'message', value: verifyError },
    ])

    return {
      localChanges: false,
      remoteChanges: false,
      error: true,
    }
  }

  // log(`Sync for ${table} is allowed ("verified")`, true, {
  //   userId: appStatus.userId,
  // })

  // Detect local changes
  let localChanges = appStatus.sync[`${table}LocalUpdates`]

  // Detect remote changes, start with getting expected remote id
  let error = false
  let remoteChanges: boolean
  const expectedRemoteId = appStatus.sync[`${table}RemoteId`]

  // Find actual remote id, use autofix if we can
  const remoteIds = await getS3Filenames(table)
  if (!remoteIds || remoteIds.length === 0) {
    // No remote file
    remoteChanges = false
    error = true
    await updateSingleSyncProperty([
      { key: 'status', value: 'error' },
      {
        key: 'message',
        value: `MISSING-${table}-Remote file for ${table} is missing`,
      },
    ])
  } else if (remoteIds[0] === 'Error') {
    // Some S3 error
    remoteChanges = true
    error = true
    await updateSingleSyncProperty([
      { key: 'status', value: 'error' },
      { key: 'message', value: remoteIds[1] },
    ])
  } else if (remoteIds.length === 1) {
    // This is the normal case: Only 1 file, is it the expected one?
    remoteChanges = expectedRemoteId !== remoteIds[0]
  } else {
    // Multiple remote ids - check if they're all the same
    const fileIds = await uniqueRemoteFiles(table, remoteIds, expectedRemoteId)
    if (fileIds.length === 1) {
      remoteChanges = expectedRemoteId !== fileIds[0]
      const analyticsMessage = `EMULSAM ${table.slice(0, 1)} ${
        remoteIds.length
      }`
      log(analyticsMessage, true, { userId: appStatus.userId })
    } else {
      // There are multiple remote files that are different
      // Find the diffs, report a bigError and keep the latest file
      remoteChanges = true
      const diffMessage = await findRemoteDifferences(
        table,
        fileIds.concat(fileIds)
      )
      const analyticsMessage = `EMULREM ${table.slice(0, 1)} ${fileIds.length}`
      log(analyticsMessage, true, { userId: appStatus.userId })
      log(diffMessage, true, { userId: appStatus.userId })
      const msg = [WARNING]
      msg.push(`It looks like a sync conflict`)
      msg.push(diffMessage)
      // Note: if walks and users both have multiple file, only the first bigError will display to the user. But both in the logs.
      await updateSingleSyncProperty([
        { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
        { key: 'status', value: 'bigError' },
      ])

      // Keep only the latest file (ie first in the list)
      fileIds.map(async (id, index) => {
        if (index === 0) return
        await deleteS3File(table, id)
      })
    }
  }

  return {
    localChanges,
    remoteChanges,
    error,
  }
}

// Compare these remote files and return the list of unique files, based on the "original"
// Remove the duplicates keeping the original
const uniqueRemoteFiles = async (
  table: TableName,
  fileIds: string[],
  originalId: string
): Promise<string[]> => {
  const referenceId = fileIds.includes(originalId) ? originalId : fileIds[0]
  const referenceContent = await getS3FileContents(table, referenceId)

  // Check if they're the same
  const uniqueIds = [referenceId]
  for (const fileId of fileIds) {
    if (fileId === referenceId) continue

    const content = await getS3FileContents(table, fileId)
    if (isEqual(referenceContent, content)) {
      log(`Fix: delete equal file for: ${table} ${fileId}`, true)
      await deleteS3File(table, fileId)
    } else {
      uniqueIds.push(fileId)
    }
  }
  return uniqueIds
}

// Find and report the name of walk/user with differences.
// Report all differences but only do the first 2 remote files
const findRemoteDifferences = async (
  table: TableName,
  remoteIds: string[]
): Promise<string> => {
  const referenceId = remoteIds[0]
  const compareId = remoteIds[1]
  const referenceContent = await getS3FileContents(table, referenceId)
  const compareContent = await getS3FileContents(table, compareId)
  const objectsWithDiffs = []
  for (const refObject of referenceContent) {
    const id = table === 'walks' ? refObject.walkId : refObject.userId
    const compareObject = compareContent.find((c: any) => {
      const cId = table === 'walks' ? c.walkId : c.userId
      return cId === id
    })
    if (isEqual(refObject, compareObject)) continue
    // Found the culprit
    objectsWithDiffs.push(compareObject)
  }
  if (!objectsWithDiffs.length) return ''
  const namesArray = objectsWithDiffs.map((o) => {
    if (!o) return 'ERROR-UNDEFINED-ITEM'
    return table === 'walks' ? o.title : o.fullName
  })
  return table === 'walks'
    ? `The data for these walks: [ ${namesArray.join(
        ', '
      )} ] may be incorrect. Please ask the leader to check`
    : `The data for these people: [ ${namesArray.join(
        ','
      )} ] may be incorrect. Please ask them to check`
}

/*const findLocalDifferences = (
  table: TableName,
  localContent: Walk[] | User[] | null,
  remoteContent: Walk[] | User[]
) => {
  if (!localContent || !remoteContent) return ''
  const diffMsgs = []
  if (localContent.length !== remoteContent.length)
    diffMsgs.push(
      `The localData has ${localContent.length} but remoteData has ${remoteContent.length} items`
    )

  for (const localObject of localContent) {
    const id = 'walkId' in localObject ? localObject.walkId : localObject.userId
    // @ts-ignore
    const remoteObject = remoteContent.find((c: Walk | User) =>
      'walkId' in c ? c.walkId === id : c.userId === id
    )
    if (isEqual(localObject, remoteObject)) continue
    const title =
      'walkId' in localObject ? localObject.title : localObject.fullName
    // Found the culprit
    diffMsgs.push(
      `FYI: These ${table} are different mergedLocal & remote: ${title}`
    )
  }

  return diffMsgs.join(', ')
}*/

// Check if the local and remote files are similar (so detect if a previous sync crashed)
const verifyLocalRemote = async (table: TableName, appStatus: AppStatus) => {
  // For now: no check for messages or plbs
  if (table === 'plbs') return ''
  if (table === 'messages') return ''

  // Get the local data
  const localContents = await getLocalData(table)

  // Get remote contents. Just look at the current remote contents,
  // so don't worry about the expected remote id
  const remoteIds = await getS3Filenames(table)
  const remoteContents = await getS3FileContents(
    table,
    (remoteIds.length && remoteIds[0]) || 'missing-id'
  )
  if (remoteContents === ERROR_GETTING_CONTENT) {
    const analyticsMessage = `EVERMIS ${table.slice(0, 1)}`
    log(analyticsMessage, true, { userId: appStatus.userId })
    const msg = [ERROR, 'Refusing to Sync']
    msg.push(
      `It looks like the shared data for ${table.toUpperCase()} is unavailable`
    )
    msg.push(`Likely the previous sync was broken off`)
    msg.push(`Please tell Mark: ${table} verify no-remote`)
    return msg.join(MESSAGE_SEPARATOR)
  }

  const lengthDiff = getLengthDiff(localContents, remoteContents)
  if (lengthDiff < -5) {
    const analyticsMessage = `WVERFIX ${table.slice(0, 1)} ${lengthDiff} ${
      localContents.length
    }/${remoteContents.length}`
    log(analyticsMessage, true, { userId: appStatus.userId })
    const msg = [WARNING]
    msg.push(
      `The number of ${table.toUpperCase()} is way less (${
        lengthDiff * -1
      }) than the shared data`
    )
    msg.push(
      `Either you haven't used the app for a while, or, the previous sync was broken off`
    )
    msg.push(`The data on this device is REPLACED with the shared data`)
    msg.push(
      `Possibly your recent changes are lost - please CHECK and sync again`
    )

    if (table === 'walks') {
      await replaceWalksTable(remoteContents)
    } else if (table === 'users') {
      await replaceUsersTable(remoteContents)
    }

    return msg.join(MESSAGE_SEPARATOR)
  } else if (lengthDiff > 2) {
    // For now: allow that local is much larger than remote. But I want to know about it, so log it
    const analyticsMessage = `WVERLOCAL ${table.slice(0, 1)} ${lengthDiff} ${
      localContents.length
    }/${remoteContents.length}`
    log(analyticsMessage, true, { userId: appStatus.userId })
    return ''
    /*
    const msg = [ERROR, 'Refusing to Sync']
    msg.push(`The number of ${table} is too different from the shared`)
    msg.push(`Likely the previous sync was broken off`)
    msg.push(`Please tell Mark: ${table} verify ${lengthDiff}`)
    return msg.join(MESSAGE_SEPARATOR)
     */
  }

  return ''
}

export const getLocalData = async (table: TableName) => {
  const localContents: User[] | Walk[] | null =
    table === 'users'
      ? ((await db.users.toArray()) as User[])
      : table === 'walks'
      ? ((await db.walks.toArray()) as Walk[])
      : null

  return localContents || []
}

// Find the length diff of local vs remote data, while ignoring deleted items
export const getLengthDiff = (
  left: (Walk | User)[],
  right: (Walk | User)[],
  ignoreStatus = 'deleted'
): number => {
  const leftCleaned = left.filter((l) => l.status !== ignoreStatus)
  const rightCleaned = right.filter((r) => r.status !== ignoreStatus)
  return leftCleaned.length - rightCleaned.length
}
