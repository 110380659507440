import {
  DeleteObjectCommand,
  ListObjectsV2Command,
  PutObjectCommand,
  S3Client,
} from '@aws-sdk/client-s3'
import { isEqual } from 'lodash'
import { constants } from '../config'
import {
  FileMeta,
  FilesContents,
  FilesMeta,
  IsClosed,
  SyncLock,
  TableName,
  User,
  Walk,
} from '../store/db'
import { log } from './utils'
import {
  CallResult,
  ERROR_GETTING_CONTENT,
  FILE_READ_ERROR,
} from './syncWithRemote'
import { fbCreateS3JsonFile } from './fbFunctions'

let S3_FOLDER_PATH = `${constants.ENVIRONMENT}/`
const S3_CONFIG_V3 = {
  region: constants.S3_REGION,
  credentials: {
    accessKeyId: constants.S3_ACCESS_KEY,
    secretAccessKey: constants.S3_SECRET,
  },
}

export const getS3Filenames = async (
  table: TableName | SyncLock | IsClosed,
  // Note: autofix = delete multiple files if they're within 1 second of each other
  //       it's not used now, but may be useful in the future
  autoFixMultiple = false
): Promise<string[]> => {
  const s3 = new S3Client(S3_CONFIG_V3)

  try {
    const command = new ListObjectsV2Command({
      Bucket: constants.S3_BUCKET_NAME,
    })
    const { Contents: fileList } = await s3.send(command)

    const prefix = `${S3_FOLDER_PATH}dexie/${table}-`
    const tableFiles =
      fileList?.filter((item: any) => item.Key.startsWith(prefix)) || []

    // Sort the list: newest first so can delete older ones if needed
    tableFiles.sort((a, b) => {
      // @ts-ignore - lastModified can be undefined
      return a && b ? b.LastModified.getTime() - a.LastModified.getTime() : 0
    })

    // Autofix multiple files with same datetime (within 1 sec)
    let updatedTableFiles = [...tableFiles]
    if (autoFixMultiple) {
      let timestampSec = 0
      updatedTableFiles = []
      tableFiles.map(async (f: any, index) => {
        if (index === 0) {
          timestampSec = Math.floor(f.LastModified.getTime() / 1000)
          updatedTableFiles.push(f)
        } else {
          const fileTimestampSec = Math.floor(f.LastModified.getTime() / 1000)
          if (Math.abs(fileTimestampSec - timestampSec) <= 1) {
            const rest = f.Key.slice(prefix.length)
            const idArray = rest.split('.')
            log('Autofix: delete multi syncFile for: ' + table, true)
            deleteS3File(table, idArray[0])
          } else {
            updatedTableFiles.push(f)
          }
          updatedTableFiles.push(f)
        }
      })
    }
    const idsList = updatedTableFiles.map((f: any) => {
      return getIdFromFile(f, prefix)
    })

    return Promise.resolve(idsList)
  } catch (error) {
    const errorMessage =
      (error as any).err || (error as any).message || 'Unknown error'
    return Promise.resolve(['List Error', errorMessage])
  }
}

const getIdFromFile = (f: any, prefix: string) => {
  const rest = f.Key.slice(prefix.length)
  const idArray = rest.split('.')
  return idArray[0]
}

export const createS3JsonFile = async (
  table: TableName | SyncLock | FilesMeta | IsClosed,
  id: string,
  fileContent: any
): Promise<CallResult> => {
  // Pass the job on to the firebase api
  try {
    const result: CallResult = await fbCreateS3JsonFile(table, id, fileContent)
    return Promise.resolve(result)
  } catch (error) {
    return Promise.resolve({
      status: 'error',
      isSuccess: false,
      message: error as string,
    })
  }

  /*// Write directly to S3
  const s3 = new S3Client(S3_CONFIG_V3)
  const filename = table === 'files-meta' ? `${id}-meta` : `${table}-${id}`
  const fileType = '.json'
  const fileTypeMime = `application/json`
  const file = new File([JSON.stringify(fileContent)], filename + fileType, {
    type: fileTypeMime,
  })
  const subFolderPath = table === 'files-meta' ? 'files/old/' : 'dexie/old/'
  const fileKey = `${S3_FOLDER_PATH}${subFolderPath}${filename}${fileType}`

  const command = new PutObjectCommand({
    Bucket: constants.S3_BUCKET_NAME,
    Key: fileKey,
    Body: file,
  })

  try {
    await s3.send(command)
    return Promise.resolve('uploaded')
  } catch (error) {
    const errorMessage =
      (error as any).err || (error as any).message || 'Unknown error'
    return Promise.resolve('Error: ' + errorMessage)
  }
   */
}

// Create a attachment file (jpg, gpx, pdf, etc)
export const createS3File = async (
  id: string,
  fileContent: File
): Promise<string> => {
  const s3 = new S3Client(S3_CONFIG_V3)
  const newFilename = id + '-contents'
  const fileNameLastPart = fileContent.name.split('.').pop()
  const fileKey = `${S3_FOLDER_PATH}files/${newFilename}.${fileNameLastPart}`

  const command = new PutObjectCommand({
    Bucket: constants.S3_BUCKET_NAME,
    Key: fileKey,
    Body: fileContent,
  })

  try {
    await s3.send(command)
    return Promise.resolve('uploaded')
  } catch (error) {
    const errorMessage =
      (error as any).err || (error as any).message || 'Unknown error'
    return Promise.resolve('Error: ' + errorMessage)
  }
}

/* Rename a file on S3. Basically a copy&delete. No speed improvement here.
export const renameS3File = async (
  oldKey: string,
  newKey: string
): Promise<string> => {
  const copyCommand = new CopyObjectCommand({
    CopySource: constants.S3_BUCKET_NAME + '/' + oldKey,
    Bucket: constants.S3_BUCKET_NAME,
    Key: newKey,
  })
  const deleteCommand = new DeleteObjectCommand({
    Bucket: constants.S3_BUCKET_NAME,
    Key: oldKey,
  })
  const s3 = new S3Client(S3_CONFIG_V3)
  try {
    await s3.send(copyCommand)
    await s3.send(deleteCommand)
    return Promise.resolve('')
  } catch (error) {
    const errorMessage =
      (error as any).err || (error as any).message || 'Unknown error'
    return Promise.resolve('Error renaming: ' + errorMessage)
  }
}
*/

export const deleteS3File = async (
  table: TableName | SyncLock | FilesMeta | FilesContents | IsClosed,
  data: string | FileMeta
): Promise<string> => {
  let id = ''
  let fileType = ''
  let filename = ''
  let subFolderPath = ''

  if (table === 'files-meta' || table === 'files-contents') {
    const fileMeta = data as FileMeta
    id = fileMeta.fileId
    const fileTypeArray = fileMeta.fileNameType.split('.')
    fileType = fileType =
      table === 'files-meta' ? `.json` : '.' + fileTypeArray.pop()
    filename = table === 'files-meta' ? `${id}-meta` : `${id}-contents`
    subFolderPath = 'files/'
  } else {
    id = data as string
    fileType = '.json'
    filename = `${table}-${id}`
    subFolderPath = 'dexie/'
  }

  const fileKey = `${S3_FOLDER_PATH}${subFolderPath}${filename}${fileType}`
  const command = new DeleteObjectCommand({
    Bucket: constants.S3_BUCKET_NAME,
    Key: fileKey,
  })

  const s3 = new S3Client(S3_CONFIG_V3)
  try {
    const result = await s3.send(command)
    return Promise.resolve('')
  } catch (error) {
    const errorMessage =
      (error as any).err || (error as any).message || 'Unknown error'
    return Promise.resolve('Error deleting: ' + errorMessage)
  }
}

export const getS3FileContents = async (
  table: TableName | SyncLock | FilesMeta | FilesContents | 'image',
  id: string,
  fileType?: string
): Promise<Walk[] | User[] | string | FileMeta | any> => {
  try {
    const fileSubPathName =
      table === 'files-meta'
        ? `files/${id}-meta.json`
        : table === 'files-contents' || table === 'image'
        ? `files/${id}-contents` + fileType
        : `dexie/${table}-${id}.json`
    const path = `${S3_FOLDER_PATH}${fileSubPathName}`

    const content = await streamFile(path)
    return content === FILE_READ_ERROR ? ERROR_GETTING_CONTENT : content
  } catch (error) {
    return ERROR_GETTING_CONTENT
  }
}

export const downloadS3File = async (fileMeta: FileMeta): Promise<void> => {
  // Find the name
  const fileTypeArray = fileMeta.fileNameType.split('.')
  const fileType = '.' + fileTypeArray.pop()
  const fileSubPathName = `files/${fileMeta.fileId}-contents` + fileType
  const path = `${S3_FOLDER_PATH}${fileSubPathName}`

  // Create and click on a link
  const content = await streamFile(path)
  if (content === FILE_READ_ERROR) {
    log(`Could not get contents of ${fileMeta.fileNameType} (${path})`)
    return
  }
  const objectUrl = URL.createObjectURL(content)
  const hiddenLink = document.createElement('a')
  hiddenLink.href = objectUrl
  hiddenLink.download = fileMeta.fileNameType
  hiddenLink.click()
  window.URL.revokeObjectURL(hiddenLink.href)
}

// Reader to actually read a file
const streamFile = async (_path: string) => {
  const s3Root = `https://${constants.S3_BUCKET_NAME}.s3-${constants.S3_REGION}.amazonaws.com/`

  try {
    const response = await fetch(s3Root + _path, {
      headers: { 'Cache-Control': 'no-cache' },
    })
    return response.url.includes('.json')
      ? await response.json()
      : await response.blob()
  } catch (error: any) {
    log(`Error reading ${_path}: ${error.message || error}`, true)
    return FILE_READ_ERROR
  }
}
