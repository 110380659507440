import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  ColorModeScript,
  extendTheme,
} from '@chakra-ui/react'
import { WarningIcon } from '@chakra-ui/icons'
import { useLiveQuery } from 'dexie-react-hooks'
import { ErrorBoundary } from 'react-error-boundary'
import { useSwipeable } from 'react-swipeable'
import packageJson from '../package.json'
import { Header } from './header/Header'
import { User } from './User/User'
import { db, MenuOptions } from './store/db'
import { syncWithRemote } from './common/syncWithRemote'
import { ErrorScreen } from './common/ErrorScreen'
import { getS3Filenames } from './common/s3Functions'
import { Start } from './Start/Start'
import { Walks } from './Walks/Walks'
import { log } from './common/utils'
import { Register } from './Register/Register'
import { Help } from './Help/Help'
import './app.css'

function App() {
  // State
  const [navTo, setNavTo] = useState<MenuOptions>('start')
  const [helpOn, setHelpOn] = useState<MenuOptions>('walks')
  const [isClosedIds, setIsClosedIds] = useState<string[]>([])
  const [localIsClosed, setLocalIsClosed] = useState(false)
  const [closedBy, setClosedBy] = useState<string>('')
  const syncState = useRef<'' | 'syncStarted'>('')

  const firstRender = useRef(true)

  // Dexie - need to catch errors independently to avoid rule-of-hooks problems
  let appStatusDexie: any
  let usersDexie: any
  try {
    appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  } catch (error: any) {
    console.error('Error getting appStatusDexie', error, appStatusDexie)
  }

  try {
    usersDexie = useLiveQuery(() => db.users.toArray())
  } catch (error: any) {
    console.error('Error getting usersDexie', error, appStatusDexie)
  }

  const zoomLevels = [100, 120, 140, 160, 80]
  const [zoomLevelIndex, setZoomLevelIndex] = useState(0)
  const [zoomValue, setZoomValue] = useState(`${zoomLevels[zoomLevelIndex]}%`)

  // Find the isClosed status and if access to S3 is available
  if (appStatusDexie?.clubId) {
    getS3Filenames('is-closed')
      .then((ids) => {
        if (ids.length) {
          if (
            ids[0] === 'List Error' &&
            ids.length > 1 &&
            ids[1]
              .toLowerCase()
              .includes('signature we calculated does not match') &&
            !isClosedIds.length
          ) {
            setIsClosedIds(['NO-ACCESS-TO-S3'])
          } else if (!isClosedIds.length) setIsClosedIds(ids)
        }
      })
      .catch((error) => {
        console.error('Error getting access to S3', error, appStatusDexie)
        setIsClosedIds(['NO-ACCESS-TO-S3'])
      })
  }

  // Handle swipe to help pagge
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      // Swipe handler for whole app. Do this per component to override
      /*
      console.log('User swiped', eventData.dir, 'from', navTo)
      setHelpOn(navTo)
      if (eventData.dir === 'Right') setNavTo('help')
      */
    },
  })

  // Effect to check if app is closed
  useEffect(() => {
    let isClosed = Boolean(
      isClosedIds.length > 0 && isClosedIds[0] !== 'List Error'
    )
    if (!isClosed) return

    const closer = usersDexie?.find((u: any) => u?.userId === isClosedIds[0])
    const closerName = closer?.fullName || isClosedIds[0] || 'Unknown'
    setClosedBy(closerName)
    setLocalIsClosed(true)

    // Am I an admin
    const user = usersDexie?.find(
      (u: any) => u?.userId === appStatusDexie?.userId
    )
    if (Boolean(user?.isSuperAdmin)) setLocalIsClosed(false)

    db.appStatus.update('Tripsheets', { isClosed })
  }, [appStatusDexie?.userId, usersDexie, isClosedIds])

  // Effect to get navTo destination
  useEffect(() => {
    if (!appStatusDexie) return
    setNavTo(appStatusDexie.navTo)
  }, [appStatusDexie?.navTo])

  // Effect to set text zoom level
  useEffect(() => {
    if (!appStatusDexie) return

    const zoomDefault = appStatusDexie?.zoomDefault ?? 0
    if (zoomDefault === zoomLevelIndex) return

    setZoomLevelIndex(zoomDefault)
    setZoomValue(`${zoomLevels[zoomDefault]}%`)
  }, [appStatusDexie?.zoomDefault])

  if (localIsClosed) {
    return (
      <Center pl={50}>
        App closed for maintenance.
        <br /> Closed by {closedBy}.
        <br /> Hopefully wont take more than 1-2 hours.
      </Center>
    )
  }

  // // Actions on app start
  // if (appStatusDexie?.sync.status === 'appStart') {
  //   // Only want to start the sync once on app start
  //   if (syncState.current === '') syncWithRemote()
  //   syncState.current = 'syncStarted'
  // }

  // Handle user statusses
  if (appStatusDexie?.userId && usersDexie) {
    const user = usersDexie.find(
      (u: any) => u?.userId === appStatusDexie.userId
    )

    // Block some users
    if (user?.isBlocked) {
      log(`User blocked:`, true, { userId: user?.userId })
      return null
    }
    if (user?.status === 'deleted') {
      log(`User not active:`, false, { userId: user?.userId })
      db.appStatus.update('Tripsheets', { userId: '', navTo: 'start' })
      return null
    }

    // Actions on app start
    if (firstRender && appStatusDexie?.sync.status === 'appStart') {
      // Only want to start the sync once on app start
      firstRender.current = false

      if (syncState.current === '') syncWithRemote()
      syncState.current = 'syncStarted'

      log(`AppStart: ${packageJson.version}-${appStatusDexie?.clubId}`, true, {
        userId: user?.userId || '?',
      })
    }
  }

  // Theme
  const customTheme = extendTheme({
    config: {
      initialColorMode: 'system',
      useSystemColorMode: true,
    },
  })

  if (appStatusDexie && !appStatusDexie.clubId) {
    return (
      <Box className={'appContainer'}>
        <Center mt={8}>
          Can't find the name of the club
          <Box ml={2}>
            <Button
              leftIcon={<WarningIcon />}
              size={'sm'}
              variant="solid"
              onClick={async () => {
                await db.delete()
                location.reload()
              }}
            >
              Retry
            </Button>
          </Box>
        </Center>
      </Box>
    )
  }

  const stepZoom = async () => {
    const newZoomIndex = (zoomLevelIndex + 1) % 5
    setZoomLevelIndex(newZoomIndex)
    setZoomValue(`${zoomLevels[newZoomIndex]}%`)
    await db.appStatus.update('Tripsheets', {
      zoomDefault: newZoomIndex,
    })
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
      <ChakraProvider theme={customTheme}>
        <div {...swipeHandlers}>
          <Box className={'appContainer'} style={{ zoom: zoomValue }}>
            <Header stepZoom={stepZoom} />
            {navTo === 'start' && <Start />}
            {navTo === 'walks' && <Walks />}
            {navTo === 'user' && <User />}
            {navTo === 'register' && <Register />}
            {navTo === 'help' && <Help source={helpOn} />}
          </Box>
        </div>
      </ChakraProvider>
    </ErrorBoundary>
  )
}

export default App
