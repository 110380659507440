// Create local backup for a table
import { differenceInMinutes, formatISO } from 'date-fns'
import { isEqual } from 'lodash'
import nid from 'nid'
import { db, Message, Plb, TableName, User, Walk } from '../store/db'
import { dbBackup } from '../store/dbBackup'
import { getS3FileContents, getS3Filenames } from '../common/s3Functions'
import { log } from '../common/utils'
import {
  ERROR_GETTING_CONTENT,
  FILE_READ_ERROR,
} from '../common/syncWithRemote'
import { ta } from 'date-fns/locale'

export const createLocalBackup = async (
  table: TableName
): Promise<void | string> => {
  const currentItems = await db[table].toArray()
  const signature = getSignature(table, currentItems)

  const lastBackup = await dbBackup[`${table}Backup`]?.toCollection().last()
  if (isEqual(currentItems, lastBackup?.items)) {
    await purgeBackups(table)
    return 'same'
  }

  const currentAppStatus = await db.appStatus.get('Tripsheets')
  log(`Backup made for ${table}`, true, { userId: currentAppStatus?.userId })

  const backupObject = {
    id: nid(),
    timestamp: formatISO(new Date()),
    tableName: table,
    signature,
    items: currentItems,
  }
  dbBackup[`${table}Backup`].add(backupObject)

  await purgeBackups(table)
}

const purgeBackups = async (table: TableName) => {
  // Check the list of backups. Keep latest, >1 hr ago, >24 hr ago. Delete all in between
  const allBackups = await dbBackup[`${table}Backup`].toArray()
  allBackups.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
  let index = -1
  let have1Hr = false
  let have24Hr = false
  for (const b of allBackups) {
    index++
    if (index === 0) continue // Keep the latest
    if (!have1Hr) {
      if (differenceInMinutes(new Date(), new Date(b.timestamp)) < 60) {
        // Delete this one
        await dbBackup[`${table}Backup`].delete(b.id)
      } else {
        have1Hr = true // Keep this one
      }
    } else {
      if (!have24Hr) {
        if (differenceInMinutes(new Date(), new Date(b.timestamp)) < 24 * 60) {
          // Delete this one
          await dbBackup[`${table}Backup`].delete(b.id)
        } else {
          have24Hr = true // Keep this one
        }
      } else {
        await dbBackup[`${table}Backup`].delete(b.id)
      }
    }
  }
}

export const getSignature = (
  table: TableName,
  items?: Walk[] | User[] | Plb[] | Message[] | string
): string => {
  if (!items || items === ERROR_GETTING_CONTENT || items === FILE_READ_ERROR)
    return ''
  const sizeKb = (JSON.stringify(items).length / 1024).toFixed(3)
  const signature = `${items.length}${table} ${sizeKb}kB`
  return signature
}

export const getRemoteSignature = async (table: TableName): Promise<string> => {
  const remoteIds = await getS3Filenames(table)
  if (!remoteIds || !remoteIds.length) return ''

  const remoteContent = await getS3FileContents(table, remoteIds[0])

  const suffix =
    remoteIds.length > 1 ? ` --- ${remoteIds.length} files !!!` : ''
  return getSignature(table, remoteContent) + suffix
}
