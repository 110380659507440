import { Fragment, useEffect, useState } from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  AddIcon,
  BellIcon,
  CheckIcon,
  CloseIcon,
  InfoOutlineIcon,
} from '@chakra-ui/icons'
import { addDays, formatISO } from 'date-fns'
import { db, Message, User } from '../store/db'
import { constants, palette, paletteDark } from '../config'
import { formatDateTime } from '../common/utils'
import { MakeMessage } from './MakeMessage'
import {
  getOthersSentToMeMessages,
  getUniqueSentByMessages,
} from './Messages.logic'

/**
 * Messages Panel - list the messages
 */
type Props = {
  onClick: () => void
}

export const MessagesList = (props: Props) => {
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const messagesDexie = useLiveQuery(() => db.messages.toArray())
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')
  const [allMessages, setAllMessages] = useState<Message[]>([])
  const [showMakeMessage, setShowMakeMessage] = useState(false)
  const [user, setUser] = useState<User>()
  const [editMessageId, setEditMessageId] = useState('')
  const [showMessageInfoFor, setShowMessageInfoFor] = useState('')

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to set all messages
  useEffect(() => {
    if (!messagesDexie) return

    // Sort on status (draft then sent-by-me) then others sent on sentAt desc
    const draftByMeMessages = messagesDexie.filter(
      (m) => m.status === 'draft' && m.fromUserId === appStatusDexie?.userId
    )

    getUniqueSentByMessages().then((sentByMe: Message[]) => {
      getOthersSentToMeMessages().then((othersSentToMe) =>
        setAllMessages(
          draftByMeMessages.concat(sentByMe).concat(othersSentToMe)
        )
      )
    })
  }, [messagesDexie, appStatusDexie?.userId])

  const messageAbbrev = (message?: string) => {
    if (!message) return ''
    if (message.length > 24) return `${message.slice(0, 22)}...`
    return message
  }

  const recipients = (messageId: string) => {
    const message = messagesDexie?.find((m) => m.messageId === messageId)
    if (!message) return ''
    if (message.to === 'all') return 'Everyone'
    if (message.to === 'walk') {
      const walk = walksDexie?.find((w) => w.walkId === message.toId)
      return walk ? `Walk: ${walk.title}` : ''
    }
    if (message.to === 'user') {
      const user = usersDexie?.find((u) => u.userId === message.toId)
      return user ? `${user.fullName}` : ''
    }
  }

  const sender = (messageId: string) => {
    const message = messagesDexie?.find((m) => m.messageId === messageId)
    if (!message) return ''
    const user = usersDexie?.find((u) => u.userId === message.fromUserId)
    return user ? user.fullName : ''
  }

  const deleteText = (messageId: string) => {
    const message = messagesDexie?.find((m) => m.messageId === messageId)
    if (!message) return ''

    // Rules:
    // sent to all: delete 30 days after sent = absolute date
    // sent to a person: delete 30 days after sent = absolute date
    // sent to a walk: 7 days after walk completes
    return message.to === 'walk'
      ? `Will be deleted ${constants.MESSAGES_WALKS_DELETE_DAYS} days after the walk completes`
      : message.sentAt
      ? 'Will be deleted around ' +
        formatDateTime(
          addDays(
            new Date(message.sentAt),
            constants.MESSAGES_DELETE_DAYS
          ).toISOString(),
          'dd-MM-yyyy'
        )
      : `Will be deleted ${constants.MESSAGES_DELETE_DAYS} days after sending`
  }

  const handleMakeMessage = () => {
    setShowMakeMessage(!showMakeMessage)
  }

  const onClose = (notSeen: boolean = false) => {
    if (!notSeen)
      db.appStatus.update('Tripsheets', {
        messagesSeenTo: formatISO(new Date()),
      })
    props.onClick()
  }

  const isSentByMe = (m: Message) =>
    m.status === 'sent' && m.fromUserId === appStatusDexie?.userId

  const formatText = (text: string) => {
    return <span>{text}</span>
  }

  return (
    <>
      <Box className={`messagesContainer ${addClass}`} bg={heavyBgColor}>
        {/* Title bar */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>Messages</Text>

          {/* Close icon */}
          <IconButton
            colorScheme={palette.action}
            aria-label="Close messages"
            icon={<CloseIcon />}
            size={'xs'}
            onClick={() => onClose(true)}
          />
        </Box>

        <Box className={'listItemContainer noPadding'}>
          <Box className={'messagesList'}>
            {allMessages.length === 0 && <Box>No messages now</Box>}
            {allMessages.map((m) => (
              <Fragment key={m.messageId}>
                <Box key={'reg-' + m.messageId} className={'messageLine'}>
                  <Box display={'flex'} alignItems={'center'}>
                    {/* Info button */}
                    <IconButton
                      icon={<InfoOutlineIcon />}
                      size={'sm'}
                      mr={2}
                      onClick={() =>
                        setShowMessageInfoFor(
                          showMessageInfoFor === m.messageId ? '' : m.messageId
                        )
                      }
                      aria-label={'Info about the message'}
                    />

                    {/* New message icon */}
                    {m.sentAt > (appStatusDexie?.messagesSeenTo || '') &&
                      !isSentByMe(m) && (
                        <IconButton
                          icon={<BellIcon />}
                          size={'sm'}
                          ml={2}
                          colorScheme={palette.action}
                          aria-label={'New message'}
                        />
                      )}

                    {/* Text and Badge */}
                    <Box ml={2} mr={2}>
                      <Flex alignItems={'center'}>
                        <Box>
                          <Text>{messageAbbrev(m.message)}</Text>
                        </Box>
                        {m.status === 'draft' && <Badge ml={2}>Draft</Badge>}{' '}
                        {isSentByMe(m) && (
                          <Badge ml={2} colorScheme={'cyan'}>
                            Sent
                          </Badge>
                        )}
                      </Flex>
                    </Box>
                  </Box>

                  {/* Edit buton */}
                  {m.status === 'draft' && (
                    <Button
                      leftIcon={<CheckIcon />}
                      size={'sm'}
                      variant="outline"
                      colorScheme={palette.actionSec}
                      onClick={() => setEditMessageId(m.messageId)}
                    >
                      Edit
                    </Button>
                  )}
                </Box>

                {showMessageInfoFor === m.messageId && (
                  <Box className={'readMessageBox'}>
                    <Box>
                      {' '}
                      <Text as={'i'} size={'xs'} fontSize={'10px'}>
                        To: {recipients(m.messageId)}
                      </Text>
                    </Box>
                    <Box>From: {sender(m.messageId)}</Box>
                    <Box>
                      {' '}
                      <Text as={'i'} size={'xs'} fontSize={'10px'}>
                        Sent at: {formatDateTime(m.sentAt)}
                      </Text>
                    </Box>
                    <Box>
                      <Text as={'i'} size={'xs'} fontSize={'10px'}>
                        {deleteText(m.messageId)}
                      </Text>
                    </Box>{' '}
                    <Divider />
                    {/* Message content */}
                    <Text whiteSpace="pre-line">{formatText(m.message)}</Text>
                  </Box>
                )}
              </Fragment>
            ))}
          </Box>

          {/* Make Button */}
          <Flex justifyContent={'end'} alignItems={'flex-end'} w={'100%'}>
            <Button
              leftIcon={<AddIcon />}
              variant="solid"
              size={'sm'}
              mt={4}
              colorScheme={'cyan'}
              onClick={handleMakeMessage}
            >
              Make a message
            </Button>

            {allMessages.length > 0 && (
              <IconButton
                icon={<CheckIcon />}
                size={'sm'}
                ml={2}
                colorScheme={'orange'}
                onClick={() => onClose()}
                aria-label={'Seen all messages'}
              />
            )}
          </Flex>
        </Box>
      </Box>

      {/* Make a message panel */}
      {(showMakeMessage || editMessageId) && (
        <MakeMessage
          editMessageId={editMessageId}
          onClick={() => {
            setEditMessageId('')
            setShowMakeMessage(false)
          }}
        />
      )}
    </>
  )
}
