import React, { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  GridItem,
  HStack,
  IconButton,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { AddIcon, CloseIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import {
  FileUpload,
  FileUploadDropzone,
  FileUploadTrigger,
} from '@saas-ui/file-upload'
import nid from 'nid'
import { formatISO } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, FileMeta, Walk } from '../store/db'
import { palette, paletteDark } from '../config'
import './walks.css'
import {
  createS3File,
  createS3JsonFile,
  deleteS3File,
  downloadS3File,
  getS3FileContents,
} from '../common/s3Functions'
import { attachmentFor } from './walks.model'
import { ButtonConfirm } from '../common/UtilComponents'
import { CallResult, syncWithRemote } from '../common/syncWithRemote'
import { isLeader } from '../common/utils'

type Props = {
  walkId?: string
  onClick: () => void
}

export const Attachments = (props: Props) => {
  const toast = useToast()
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')
  const [walk, setWalk] = useState<Walk>()
  const [amAdmin, setAmAdmin] = useState(false)
  const [fileMetas, setFileMetas] = useState<FileMeta[]>([])
  const [nrToUpload, setNrToUpload] = useState(6)
  const [isUploading, setIsUploading] = useState(false)

  // Effect to get the admin status
  useEffect(() => {
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    setAmAdmin(Boolean(user?.isSuperAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to get the walk info
  useEffect(() => {
    if (!props.walkId) return
    const walkDexie = walksDexie?.find((w) => w.walkId === props.walkId)
    if (walkDexie) {
      setWalk(walkDexie)

      // Get the relevant file metas
      const allMetaPromises =
        walkDexie.fileIds?.map(async (fileId) => {
          const meta = (await getS3FileContents(
            'files-meta',
            fileId
          )) as FileMeta
          return meta
        }) || []

      const okMetas: FileMeta[] = []
      Promise.allSettled(allMetaPromises).then((results) => {
        let failCount = 0
        results.forEach((result) => {
          if (result.status === 'fulfilled') {
            okMetas.push(result.value)
          } else {
            failCount += 1
          }
        })
        setFileMetas(okMetas)
        setNrToUpload(6 - okMetas.length)
        if (failCount) {
          toast({
            title: `Error while getting ${failCount} files.`,
            isClosable: true,
            duration: 8_000,
          })
        }
      })
    }
  }, [walksDexie, props.walkId])

  const startUploads = async (files: File[]) => {
    if (!files || !files.length) return

    // Validate the gpx files ('cos they don't have a file type!?)
    const filesToUpload = files.filter((f) => {
      if (f.type !== '') return true
      if (f.name.slice(-4) === '.gpx') return true
      toast({
        title: `${f.name} is not .GPX. Skipping it.`,
        isClosable: true,
        duration: 8_000,
      })
      return false
    })

    setIsUploading(true)
    const metaResultPromises: Promise<CallResult>[] = []
    const contentResultPromises: Promise<string>[] = []
    const metasToUpload: FileMeta[] = []
    filesToUpload.map((f) => {
      const fileId = nid()
      const metaInfo: FileMeta = {
        fileId,
        walkId: props.walkId || '',
        walkTitle: walk?.title || '',
        fileNameType: f.name,
        mimeType: f.type,
        uploadedAt: formatISO(new Date()),
      }
      metasToUpload.push(metaInfo)
      metaResultPromises.push(createS3JsonFile('files-meta', fileId, metaInfo))
      contentResultPromises.push(createS3File(fileId, f))
    })
    const allMetaResults = await Promise.all(metaResultPromises)
    const allContentResults = await Promise.all(contentResultPromises)
    setIsUploading(false)
    let index = -1
    for (const mr of allMetaResults) {
      index += 1
      const cr = allContentResults[index]
      if (mr.isSuccess && cr === 'uploaded') {
        // Add to list of fileIds in the walk
        await attachmentFor(props?.walkId || '', metasToUpload[index].fileId)
      } else {
        const msg = !mr.isSuccess ? mr.message : cr
        toast({
          title: `${filesToUpload[index].name} didn't upload with message: ${msg}`,
          isClosable: true,
          duration: 15_000,
        })
      }
    }

    await syncWithRemote()
  }

  const downloadFile = async (fileMeta: FileMeta) => {
    await downloadS3File(fileMeta)
  }

  const deleteFile = async (fileMeta: FileMeta) => {
    // Remove from the walk
    await attachmentFor(fileMeta.walkId, fileMeta.fileId, 'remove')

    // Delete the actual files
    await deleteS3File('files-meta', fileMeta)
    await deleteS3File('files-contents', fileMeta)
  }

  return (
    <>
      <Box className={`makeWalkContainer ${addClass}`} bg={heavyBgColor}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>Attachments for: {walk?.title}</Text>

          {/* Close icon */}
          <IconButton
            colorScheme={palette.action}
            aria-label="Close attachments"
            icon={<CloseIcon />}
            size={'xs'}
            onClick={() => {
              syncWithRemote() // Dont wait
              props.onClick()
            }}
          />
        </Box>

        <SimpleGrid columns={6} alignItems="center">
          <GridItem colSpan={6} h={8}></GridItem>
          {fileMetas.map((meta) => (
            <Fragment key={meta.fileId}>
              <GridItem colSpan={4}>{meta.fileNameType}</GridItem>
              <GridItem colSpan={2}>
                <Flex justifyContent={'flex-end'}>
                  <IconButton
                    icon={<DownloadIcon />}
                    size={'md'}
                    onClick={(e) => downloadFile(meta)}
                    aria-label={'Download the attachment'}
                  />
                  {(amAdmin || isLeader(appStatusDexie?.userId, walk)) && (
                    <ButtonConfirm
                      text={meta.fileNameType}
                      btnText={''}
                      okAction={() => deleteFile(meta)}
                      icon={<DeleteIcon />}
                    />
                  )}
                </Flex>
              </GridItem>
            </Fragment>
          ))}
          <GridItem colSpan={6} h={8} display={'flex'} alignItems={'center'}>
            <Divider />
          </GridItem>
          <GridItem colSpan={6} display={'flex'} alignItems={'center'}>
            {isUploading ? (
              <Flex justifyContent={'center'} w={'100%'}>
                <Spinner size="xl" color={palette.action} />
              </Flex>
            ) : !nrToUpload ? null : (
              <FileUpload
                maxFileSize={5 * 1024 * 1024}
                maxFiles={nrToUpload}
                accept="image/*,application/pdf,application/gpx,"
              >
                {({ files, deleteFile, clearFiles }) => (
                  <FileUploadDropzone className={'dropzone'}>
                    <Text fontSize="sm" as={'b'}>
                      Drop here max {nrToUpload} files: GPX, PDF or image. Max 5
                      Mb each.
                    </Text>
                    <VStack>
                      {files.map((file) => (
                        <Flex
                          alignItems={'center'}
                          key={file.name + file.lastModified}
                        >
                          <Text fontSize="sm">{file.name}</Text>
                          <IconButton
                            icon={<DeleteIcon />}
                            size={'sm'}
                            ml={1}
                            onClick={(e) => {
                              e.stopPropagation()
                              deleteFile(file)
                            }}
                            aria-label={'Remove the attachment to upload'}
                          />
                        </Flex>
                      ))}

                      {/* Upload button */}
                      <HStack>
                        {/* If required use this for a file select: */}
                        <FileUploadTrigger as={Button}>
                          Select Attachments
                        </FileUploadTrigger>
                        {files.length && (
                          <Button
                            leftIcon={<AddIcon />}
                            size={'sm'}
                            variant="outline"
                            colorScheme={palette.actionSec}
                            onClick={async () => {
                              await startUploads(files)
                              clearFiles()
                            }}
                          >
                            Upload {files.length}
                          </Button>
                        )}
                      </HStack>
                    </VStack>
                  </FileUploadDropzone>
                )}
              </FileUpload>
            )}
          </GridItem>
        </SimpleGrid>
      </Box>
    </>
  )
}
