import React, { useEffect, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import {
  CloseIcon,
  DeleteIcon,
  InfoOutlineIcon,
  RepeatIcon,
  SearchIcon,
} from '@chakra-ui/icons'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, User, Walk } from '../store/db'
import { palette, paletteDark } from '../config'
import { WalkInfo } from './WalkInfo'
import {
  collapseText,
  formatDateTime,
  formatMultiDateTime,
  formatWalkTitle,
} from '../common/utils'
import './walks.css'
import { MakeWalk } from './MakeWalk'
import { ButtonConfirm } from '../common/UtilComponents'
import { hardDeleteWalkFromDb } from './walks.model'
import { getBorderColorClass, sortOnADate } from './Walks.helpers'

type Props = {
  onClick: () => void
}
export const WalkHistory = (props: Props) => {
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')
  const [endedWalks, setEndedWalks] = useState<Walk[]>([])
  const [filteredWalks, setFilteredWalks] = useState<Walk[]>([])
  const [showWalkInfoFor, setShowWalkInfoFor] = useState('')
  const [showMakeWalkIsOpen, setShowMakeWalkIsOpen] = useState(false)
  const [editWalkId, setEditWalkId] = useState('')
  const [amSuperAdmin, setAmSuperAdmin] = useState(false)
  const [user, setUser] = useState<User>()
  const [showDeletedOnly, setShowDeletedOnly] = useState(false)
  const [repeat, setRepeat] = useState(false)

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to get the admin status
  useEffect(() => {
    const _user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    if (!_user) return // Sometimes usersDexie comes in late
    setAmSuperAdmin(Boolean(_user?.isSuperAdmin))
    setUser(_user)
  }, [usersDexie, appStatusDexie?.userId])

  // Effect to set the completed or deleted walks
  useEffect(() => {
    const deletedWalksDexie =
      walksDexie?.filter((w) => w.status === 'deleted') || []
    sortOnADate(deletedWalksDexie)

    const endedWalksDexie =
      walksDexie?.filter(
        (w) => w.status === 'ended' || w.status === 'cancelled'
      ) || []
    sortOnADate(endedWalksDexie)

    const useTheseWalks = showDeletedOnly ? deletedWalksDexie : endedWalksDexie

    // Get the walkers
    const walksWithWalkers = useTheseWalks.map((w) => {
      const names = w.walkers.map(
        (wId) => usersDexie?.find((u) => u.userId === wId)?.fullName || ''
      )
      w.walkers = names
      return w
    })

    setEndedWalks(walksWithWalkers)
    setFilteredWalks(walksWithWalkers)
  }, [walksDexie, usersDexie, showDeletedOnly])

  const handleSearchFor = (event: any) => {
    const value = collapseText(event.target.value)
    const found = endedWalks.filter((w) => {
      const title = collapseText(w.title).includes(value)
      const meet = collapseText(w.meetPoint).includes(value)
      const allWalkersCollapsed = w.walkers
        .map((w) => collapseText(w))
        .join(',')
      const walkers = allWalkersCollapsed.includes(value)
      return title || meet || walkers
    })
    setFilteredWalks(found)
  }

  const hardDeleteWalk = async (walkId: string) => {
    const walk = walksDexie?.find((u) => u?.walkId === walkId)
    if (walk?.status !== 'deleted') return
    await hardDeleteWalkFromDb(walkId)
  }

  return (
    <>
      <Box className={`walkHistoryContainer ${addClass}`} bg={heavyBgColor}>
        {/* Title bar */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>
            Walk history (showing {filteredWalks.length} of {walksDexie?.length}
            )
          </Text>

          {/* Close icon */}
          <IconButton
            colorScheme={palette.action}
            aria-label="Close walk history"
            icon={<CloseIcon />}
            size={'xs'}
            onClick={props.onClick}
          />
        </Box>

        {/* Search box */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <InputGroup>
            <InputRightElement pointerEvents="none" mt={4} mb={4}>
              <SearchIcon color="gray.400" />
            </InputRightElement>
            <Input
              id={'searchFor'}
              placeholder="Search on walk, meet or walker"
              onChange={handleSearchFor}
              mt={4}
              mb={4}
            />
          </InputGroup>

          {amSuperAdmin && (
            <Button
              leftIcon={<DeleteIcon />}
              size={'sm'}
              variant="outline"
              mr={2}
              colorScheme={palette.actionSec}
              onClick={() => setShowDeletedOnly(!showDeletedOnly)}
            >
              {showDeletedOnly ? 'Not Deleted' : 'Only'}
            </Button>
          )}
        </Box>

        <Box className={'listItemContainer noPadding'}>
          <Box className={'registerWalksList'}>
            {filteredWalks.map((w) => (
              <Box key={'reg-' + w.walkId} className={'registerWalksItem'}>
                <Box
                  className={`walkItem ${getBorderColorClass(
                    walksDexie,
                    w.walkId
                  )}`}
                >
                  {/* Info button */}
                  <IconButton
                    icon={<InfoOutlineIcon />}
                    size={'sm'}
                    mr={2}
                    onClick={() => setShowWalkInfoFor(w.walkId)}
                    aria-label={'Info about the walk'}
                  />
                  {/* Repeat button */}
                  <ButtonConfirm
                    text={`Repeat "${formatWalkTitle(w.title, true)}"`}
                    btnText={''}
                    okAction={() => {
                      setRepeat(true)
                      setEditWalkId(w.walkId)
                      setShowMakeWalkIsOpen(!showMakeWalkIsOpen)
                    }}
                    icon={<RepeatIcon />}
                  />{' '}
                  {/* Delete button */}
                  {w.status === 'deleted' && (
                    <ButtonConfirm
                      icon={<DeleteIcon />}
                      okAction={() => hardDeleteWalk(w.walkId)}
                      text={`"${formatWalkTitle(
                        w.title,
                        true
                      )}" didn't happen. Must sync Before & After! `}
                      btnText={''}
                    />
                  )}
                  {/* Badge */}
                  <Box ml={2} mr={2}>
                    <Flex alignItems={'center'}>
                      <Box>
                        <Text as={w.status === 'cancelled' ? 's' : undefined}>
                          {formatWalkTitle(w.title, true)}
                        </Text>
                      </Box>
                      {w.walkers.includes(user?.fullName as string) && (
                        <Badge ml={2} colorScheme={'cyan'}>
                          You
                        </Badge>
                      )}
                    </Flex>
                    <Text fontSize={'xs'}>
                      {w.category !== 'multiday'
                        ? formatDateTime(w.date, 'EEEE d MMM yyyy')
                        : formatMultiDateTime(w.date, w.dateTo)}
                    </Text>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Walk info panel */}
      {showWalkInfoFor && (
        <WalkInfo
          walkId={showWalkInfoFor}
          onClick={() => setShowWalkInfoFor('')}
          editWalk={() => {
            setEditWalkId(showWalkInfoFor)
            setShowMakeWalkIsOpen(!showMakeWalkIsOpen)
          }}
        />
      )}

      {/* Make/Edit a walk panel */}
      {showMakeWalkIsOpen && (
        <MakeWalk
          onClick={() => {
            setShowMakeWalkIsOpen(!showMakeWalkIsOpen)
            props.onClick()
          }}
          editWalkId={editWalkId}
          repeat={repeat}
        />
      )}
    </>
  )
}
