import { Fragment, useEffect, useState } from 'react'
import {
  Badge,
  Center,
  Flex,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { isEqual, uniqWith } from 'lodash'
import { db, Message, Plb, TableName, User, Walk } from '../store/db'
import { getS3FileContents } from '../common/s3Functions'

type Chip = { id: string; name: string }
type AnyTable = Walk | User | Plb | Message

export const CompareLocalRemote = () => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const plbsDexie = useLiveQuery(() => db.plbs.toArray())
  const messagesDexie = useLiveQuery(() => db.messages.toArray())

  const [table, setTable] = useState<TableName>()
  const [errorMessage, setErrorMessage] = useState('')
  const [chips, setChips] = useState<Chip[]>([])
  const [localData, setLocalData] = useState<AnyTable[]>([])
  const [remoteData, setRemoteData] = useState<AnyTable[]>([])
  const [remoteItem, setRemoteItem] = useState<AnyTable>()
  const [localItem, setLocalItem] = useState<AnyTable>()

  useEffect(() => {
    if (!table) return
    const _localData =
      table === 'users'
        ? usersDexie
        : table === 'walks'
        ? walksDexie
        : table === 'plbs'
        ? plbsDexie
        : messagesDexie
    if (_localData) setLocalData(_localData)

    const remoteId = appStatusDexie?.sync[`${table}RemoteId`]
    if (!remoteId) return

    getS3FileContents(table, remoteId)
      .then((_remoteData) => {
        setRemoteData(_remoteData)
        setErrorMessage(
          _remoteData ? '' : `Expected remote file not available for ${table}`
        )
      })
      .catch((e) => {
        setErrorMessage(`Error getting remote file for ${table}: ${e}`)
      })
  }, [table, appStatusDexie?.sync])

  const findItem = async (event: any) => {
    const searchFor = event.target.value.toLowerCase()

    const allItemsIdsWithDuplicates = localData.concat(remoteData)

    const allData = uniqWith(allItemsIdsWithDuplicates, (a, b) => {
      const aId =
        'userId' in a
          ? a.userId
          : 'walkId' in a
          ? a.walkId
          : 'plbId' in a
          ? a.plbId
          : a.messageId

      const bId =
        'userId' in b
          ? b.userId
          : 'walkId' in b
          ? b.walkId
          : 'plbId' in b
          ? b.plbId
          : b.messageId

      return aId === bId
    })

    const allItems = allData?.filter((i: AnyTable) => {
      if ('walkId' in i) {
        return (
          i.walkId.toLowerCase().includes(searchFor) ||
          i.title.toLowerCase().includes(searchFor)
        )
      }
      if ('userId' in i) {
        return (
          i.userId.toLowerCase().includes(searchFor) ||
          i.fullName.toLowerCase().includes(searchFor)
        )
      }
      if ('plbId' in i) {
        return (
          i.plbId.toLowerCase().includes(searchFor) ||
          i.label.toLowerCase().includes(searchFor)
        )
      }
      return (
        i.messageId.toLowerCase().includes(searchFor) ||
        i.message.toLowerCase().includes(searchFor)
      )
    })

    const chips = allItems.map((i: any) =>
      table === 'users'
        ? { id: i.userId, name: i.fullName }
        : table === 'walks'
        ? { id: i.walkId, name: i.title }
        : table === 'plbs'
        ? { id: i.plbId, name: i.label }
        : { id: i.messageId, name: i.message }
    )
    setChips(chips.slice(0, 5))
  }

  const handleTableChange = (table: TableName) => {
    setChips([])
    setTable(table)
  }

  const compareThis = (id: string) => {
    const _localItem =
      table === 'walks'
        ? walksDexie?.find((w) => w.walkId === id)
        : table === 'users'
        ? usersDexie?.find((u) => u.userId === id)
        : table === 'plbs'
        ? plbsDexie?.find((p) => p.plbId === id)
        : messagesDexie?.find((m) => m.messageId === id)

    const key =
      table === 'walks'
        ? 'walkId'
        : table === 'users'
        ? 'userId'
        : table === 'plbs'
        ? 'plbId'
        : 'messageId'
    const _remoteItem = Array.isArray(remoteData)
      ? // @ts-ignore - doesnt like key
        remoteData.find((d) => d[key] === id)
      : undefined

    setLocalItem(_localItem)
    setRemoteItem(_remoteItem)
  }

  return (
    <>
      <Flex alignItems={'center'}>
        <RadioGroup onChange={handleTableChange} value={table}>
          <Stack direction="row">
            <Radio value="walks">Walks</Radio>
            <Radio value="users">Users</Radio>
            <Radio value="plbs">Plbs</Radio>
            <Radio value="messages">Messages</Radio>
          </Stack>
        </RadioGroup>

        <Input
          variant={'outline'}
          placeholder={`Id or ${
            table === 'walks'
              ? 'title'
              : table === 'users'
              ? 'name'
              : table === 'plbs'
              ? 'label'
              : 'message'
          }`}
          ml={4}
          onChange={findItem}
        />
      </Flex>
      <Center>{errorMessage}</Center>
      <Center mt={4} mb={4}>
        {chips.map((c) => (
          <Badge mr={3} onClick={() => compareThis(c.id)}>
            {c.name.slice(0, 20)}
          </Badge>
        ))}
      </Center>
      <SimpleGrid columns={3}>
        <GridItem>
          {localData.length === remoteData.length ? (
            ''
          ) : (
            <Flex justifyContent={'flex-end'}>
              <Badge colorScheme={'red'} mr={3}>
                Difference in number!
              </Badge>
            </Flex>
          )}
        </GridItem>
        <GridItem>
          <Badge colorScheme={'cyan'} mr={3}>
            Local x {localData.length}
          </Badge>
        </GridItem>
        <GridItem>
          <Badge colorScheme={'cyan'} mr={3}>
            Remote x {remoteData.length}
          </Badge>
        </GridItem>
        {(localItem || remoteItem) &&
          Object.keys(localItem || remoteItem || {}).map((localKey) => {
            // @ts-ignore - doesnt like string as key
            const local = localItem && localItem[localKey]
            // @ts-ignore - doesnt like string as key
            const remote = remoteItem && remoteItem[localKey]
            return (
              <Fragment key={'compare-' + localKey}>
                <GridItem>{localKey}</GridItem>
                <GridItem>{JSON.stringify(local)}</GridItem>
                <GridItem>
                  {isEqual(local, remote) ? '=' : JSON.stringify(remote)}
                </GridItem>
              </Fragment>
            )
          })}
      </SimpleGrid>
    </>
  )
}
